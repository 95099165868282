import PageHead from "../Head";
import { Provider } from "react-redux";
import Store from "@/redux/store";
import BackToTop from "../backToTop";

import Context from "@/context/Context";
import MobileMenu from "@/components/Header/MobileMenu";
import FooterOne from "@/components/Footer/Footer-One";
import MenfisLanding from "@/components/Menfis-Home/MenfisHome";
import HeaderBasic from "@/components/Header/HeaderStyle-Four";
import Separator from "@/components/Common/Separator";

const MenfisHome = () => {
  return (
    <>
      <PageHead title="Colegio Menfis | Educación con enfoque Bilingüe y Lúdico-Pedagógica en Chinchiná" isHomePage/>
      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderBasic headerType="" />
          <MenfisLanding />
          <BackToTop />
          <Separator />
          <FooterOne />
        </Context>
      </Provider>
    </>
  );
};

export default MenfisHome;
