import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Autoplay } from "swiper/modules";

import Brands from "../../data/elements/brands.json";

const BrandOne = () => {
  return (
    <>
      {Brands &&
        Brands.brandOne.map((data, index) => (
          <div className="row align-items-center g-5" key={index}>
            <div className="col-lg-3">
              <div className="brand-content-left">
                <h4 className="mb--0">{data.title}</h4>
              </div>
            </div>
            <div className="col-lg-9">
              <Swiper
                className="swiper rbt-arrow-between"
                modules={[FreeMode, Navigation, Autoplay ]}
                slidesPerView={6}
                spaceBetween={10}
                loop={true}
                autoHeight={true}
                navigation={{
                  nextEl: ".partners-right",
                  prevEl: ".partners-left",
                  clickable: true,
                }}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
              >
                <div className="swiper-wrapper">
                  {data.brandLogo.map((item, innerIndex) => (
                    <SwiperSlide className="swiper-slide">
                        <Image
                          src={item.img}
                          width={item.width}
                          height={item.height}
                          alt="Menfis partners logo"
                        />
                    </SwiperSlide>
                  ))}
                </div>
                <div className="rbt-swiper-arrow rbt-arrow-left partners-left">
                  <div className="custom-overfolow">
                    <i className="rbt-icon feather-arrow-left"></i>
                    <i className="rbt-icon-top feather-arrow-left"></i>
                  </div>
                </div>

                <div className="rbt-swiper-arrow rbt-arrow-right partners-right">
                  <div className="custom-overfolow">
                    <i className="rbt-icon feather-arrow-right"></i>
                    <i className="rbt-icon-top feather-arrow-right"></i>
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        ))}
    </>
  );
};

export default BrandOne;
