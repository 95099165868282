import React, { useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import sal from "sal.js";

import "venobox/dist/venobox.min.css";

import EventData from "../../data/events.json";
import MenfisBanner from "./MenfisBanner";
import ServiceEight from "../Services/Service-Eight";
import AdvanceTab from "../AdvanceTab/AdvanceTab";
import Events from "../Events/Events";

import Gallery from "../Gallery/Gallery";
import BrandOne from "../Brand/Brand-One";

import sportsOne from "../../public/images/sports/menfis_sports_1.jpg";
import sportsTwo from "../../public/images/sports/menfis_sports_5.jpg";
import sportsThree from "../../public/images/sports/menfis_sports_3.jpg";
import videoImg from "../../public/images/others/menfis_video_cover_1.jpeg";
import whyOne from "../../public/images/logo/ARTE_Y_CULTURA.png";
import whyTwo from "../../public/images/logo/BILINGUAL_PROJECT.png";
import whyTree from "../../public/images/logo/COMPETENCIAS_CIUDADANAS.png";
import whyFour from "../../public/images/logo/DEPORTE.png";
import whyFive from "../../public/images/logo/EMPRENDIMIENTO.png";
import whySix from "../../public/images/logo/FORMACION_EN_VALORES.png";
import whySeven from "../../public/images/logo/LIDERAZGO.png";
import whyEight from "../../public/images/logo/SEMILLEROS.png";
import whyNine from "../../public/images/logo/SOSTENIBILIDAD.png";
import whyTen from "../../public/images/logo/STEAM.png";

import internationalFam from "../../public/images/families/menfis_international_1.jpg";

const MenfisLanding = () => {
  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
      });
    });

    sal({
      threshold: 0.01,
      once: true,
    });
  }, []);

  return (
    <>
      <div className="rbt-slider-main-wrapper position-relative">
        <MenfisBanner />
      </div>

      <div
        className="rbt-video-area bg-color-white rbt-section-gapTop"
        id="about"
      >
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="video-popup-wrapper">
                <Image
                  className="w-100 rbt-radius"
                  src={videoImg}
                  width={638}
                  height={407}
                  property="true"
                  alt="Video Images"
                />
                <Link
                  className="rbt-btn rounded-player popup-video position-to-top rbtplayer"
                  data-vbtype="video"
                  href="https://youtu.be/0LKHRdJ_2pc?si=6EJCMfCKXxCAh3rK"
                >
                  <span>
                    <i className="feather-play"></i>
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner pl--50 pl_lg--0 pl_md--0 pl_sm--0">
                <div className="section-title text-start">
                  <h4 className="title">Accept the billingual challenge.</h4>
                  <p className="description mt--30">
                    En nuestro Colegio, nos comprometemos a apoyar a toda la comunidad menfisiana en el desafío del bilingüismo. Nuestros profesores nativos están aquí para guiar no solo a nuestros estudiantes, sino también a padres, profesores y comunidad chinchinense que considere como nosotros, la importancia de un segundo idioma.
                  </p>
                  <div className="rbt-feature-wrapper mt--40">
                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon bg-pink-opacity">
                        <i className="feather-heart"></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">Inmersión al bilingüismo</h6>
                      </div>
                    </div>

                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon bg-primary-opacity">
                        <i className="feather-book"></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">Semilleros de investigación</h6>
                      </div>
                    </div>

                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon bg-coral-opacity">
                        <i className="feather-monitor"></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">
                          Intercambios educativos
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-advance-tab-area rbt-section-gapTop bg-color-white">
        <AdvanceTab
          tag=""
          title="Nuestra Institución"
          desc=" En el corazón de la región cafetera de Caldas, rodeado por verdes paisajes y un clima cálido, se encuentra el Colegio Menfis, un lugar donde la educación y los valores se unen para formar líderes bilingües para el futuro."
          hasButton 
          buttonText="Conócenos" 
          buttonLink="/about-us"
        />
      </div>

      <div
        className="rbt-program-area rbt-section-gapTop bg-color-white"
        id="why-us"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Por qué Menfis</h2>
              </div>
            </div>
          </div>
          <ul className="brand-list brand-style-2 justify-content-center justify-content-lg-between">
              <li>
                <Image
                  src={whyOne}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li> 
                <Image
                  src={whyTwo}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li>
                <Image
                  src={whyTree}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li>
                <Image
                  src={whyFour}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li>
                <Image
                  src={whyFive}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li>
                <Image
                  src={whySix}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li>
                <Image
                  src={whySeven}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li>
                <Image
                  src={whyEight}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li>
                <Image
                  src={whyNine}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
              <li>
                <Image
                  src={whyTen}
                  width={250}
                  height={300}
                  alt="why us Images"
                />
              </li>
          </ul>
          
        </div>
      </div>
      <div
        className="rbt-program-area rbt-section-gapTop bg-color-white"
        id="after-class-activities"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Actividades Lúdicas</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link className="rbt-moderbt-btn" href="/comming-soon">
                <span class="moderbt-btn-text">
                  Conoce nuestras Lúdicas
                </span>
                <i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="row g-5">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-category-gallery">
                <div className="thumbnail">
                  <Link href="#">
                    <Image src={sportsTwo} property="true" alt="Gallery Images" />
                    <div className="rbt-bg-overlay"></div>
                  </Link>
                  <div className="hover-content">
                    <h3 className="title">
                      <Link href="#">Fútbol</Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-category-gallery">
                <div className="thumbnail">
                  <Link href="#">
                    <Image src={sportsThree} property="true" alt="Gallery Images" />
                    <div className="rbt-bg-overlay"></div>
                  </Link>
                  <div className="hover-content">
                    <h3 className="title">
                      <Link href="#">Natación</Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-category-gallery">
                <div className="thumbnail">
                  <Link href="#">
                    <Image
                      src={sportsOne}
                      property="true"
                      alt="Gallery Images"
                    />
                    <div className="rbt-bg-overlay"></div>
                  </Link>
                  <div className="hover-content">
                    <h3 className="title">
                      <Link href="#">Judo</Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container rbt-section-gapTop mb-5">
        <div className="row g-5 align-items-end mb--60">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="section-title text-start">
              <h2 className="title">Semilleros</h2>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="load-more-btn text-start text-lg-end">
              <Link className="rbt-moderbt-btn" href="/seedbed">
              <span class="moderbt-btn-text">
                Conoce nuestros Semilleros
              </span>
              <i className="feather-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <ServiceEight isHead={false} />
      </div>

      <div className="container rbt-section-gapTopHalf bg-color-light mt--80 pb--60">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 pl--20">
              <Image
                className="rbt-radius"
                src={internationalFam}
                width={580}
                alt="International families pic"
              />
          </div>
          <div className="col-lg-6">
            <div className="inner pl--50 pl_lg--0 pl_md--0 pl_sm--0 pr--20">
              <div className="section-title text-start">
                <h4 className="title">World Schooling</h4>
                <p className="description mt--30 mt_md--15 mt_sm--15 mb_md--15 mb_sm--15">
                  Welcome!
                </p>
                <p className="mb_md--15 mb_sm--15">
                  En el colegio Menfis estamos muy felices de recibir a familias de todo el mundo que eligen Chinchiná como destino para enriquecer la educación de sus hijos. Aquí, no solo les ofrecemos la oportunidad de aprender el idioma español, sino también de sumergirse en una nueva cultura y vivir una experiencia educativa única. 
                </p>
                <div className="view-all-button">
                  <Link
                    className="rbt-btn btn-border btn-border__blue hover-icon-reverse"
                    href="/world-schooling"
                  >
                    <span className="icon-reverse-wrapper">
                      <span className="btn-text">Conoce su experiencia</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-event-area bg-color-white rbt-section-gapTop mb-5">
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Nuestros Eventos</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
              <Link className="rbt-moderbt-btn" href="/calendar">
                <span class="moderbt-btn-text">
                  Calendario Escolar
                </span>
                <i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <Events
            start={0}
            end={3}
            isPagination={false}
            parentClass="card-list-2 event-list-card"
            childClass="col-lg-6 col-md-6 col-12"
            getEvents={EventData}
          />
        </div>
      </div>

      <div className="rbt-testimonial-area bg-color-light rbt-section-gap overflow-hidden">
        <div className="wrapper mb--60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle bg-primary-opacity">
                    EDUCATION FOR EVERYONE
                  </span>
                  <h2 className="title">
                    People like bilingual education. <br /> No joking - Parents
                    Review Here!
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="elfsight-app-36d1a18c-599c-4288-ab5e-7269ae2f0792" data-elfsight-app-lazy></div>
      </div>
      <div className="rbt-gallery-area">
        <Gallery />
      </div>
      <div className="rbt-brand-area bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row align-items-center g-5">
            <BrandOne />
          </div>
        </div>
      </div>
      <div className="rbt-google-map bg-color-white">
        <iframe
          className="w-100"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15899.03080222067!2d-75.6086459!3d4.9798837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e477c088680fdd5%3A0xeb6fcece048fe336!2sCentro%20Educativo%20Menfis!5e0!3m2!1ses-419!2sco!4v1714736419974!5m2!1ses-419!2sco"
          height="600"
          style={{ border: "0" }}
        ></iframe>
      </div>
    </>
  );
};

export default MenfisLanding;
