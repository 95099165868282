import Link from "next/link";
const SectionHead = ({ tag, title, desc, hasButton, buttonText, buttonLink }) => {
  return (
    <div className="row mb--60">
      <div className="col-lg-12">
        <div className="section-title text-center">
          {tag === "" ? (
            ""
          ) : (
            <span className="subtitle bg-secondary-opacity">{tag}</span>
          )}
          <h2 className="title">{title}</h2>
          {desc === "" ? "" : <p className="description mt--30">{desc}</p>}
          {hasButton ? 
            <Link class="rbt-btn hover-icon-reverse" href={buttonLink}>
              <div class="icon-reverse-wrapper">
                <span class="btn-text">{buttonText}</span>
                <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                <span class="btn-icon"><i class="feather-arrow-right"></i></span>
              </div>
            </Link>
            :
            null
            }
        </div>
      </div>
    </div>
  );
};

export default SectionHead;
